<template>
    <div class="monitoring padding-left-layout padding-top-layout padding-right-layout">
        <div class="monitoring__block">
            <div class="monitoring__block__header">
                <div class="monitoring__block__header__content">
                    <page-title :title="$t('casbee_monitoring.label_explain')" />
                </div>
            </div>
            <div class="monitoring__block__body">
                <div class="monitoring__block__body__content">
                    <h2 class="monitoring-table-title">{{ $t("casbee_monitoring.label_exam_efficient_operation") }}</h2>
                    <div class="monitoring-outside-table">
                        <table class="monitoring-table">
                    <thead class="monitoring-table-header">
                        <tr class="header-table">
                            <th>{{ $t("casbee_monitoring.header_table_effort") }}</th>
                            <th>{{ $t("casbee_monitoring.header_table_building_use") }}</th>
                            <th>{{ $t("casbee_monitoring.header_table_monitoring") }}</th>
                            <th>{{ $t("casbee_monitoring.content_use_management_system") }}</th>
                        </tr>
                    </thead>
                    <tbody class="monitorng-table-body">
                        <tr class="body-table">
                            <th rowspan="2">{{ $t("casbee_monitoring.content_level_4") }}</th>
                            <th>{{ $t("casbee_monitoring.content_housing_complex") }}</th>
                            <th>{{ $t("casbee_monitoring.content_device_related") }}</th>
                            <th> {{ $t("casbee_monitoring.content_instruction_manuals") }}</th>
                        </tr>
                        <tr class="body-table">
                            <th> {{ $t("casbee_monitoring.content_collective_housing") }}</th>
                            <th> {{ $t("casbee_monitoring.content_analysis_and_validity") }}</th>
                            <th>{{ $t("casbee_monitoring.content_operational_management") }}</th>
                        </tr>
                        <tr class="body-table">
                            <th rowspan="2">{{ $t("casbee_monitoring.content_level_5") }}</th>
                            <th>{{ $t("casbee_monitoring.content_housing_complex") }}</th>
                            <th>{{ $t("casbee_monitoring.content_manage_energy") }}</th>
                            <th>{{ $t("casbee_monitoring.content_appropriate_explanations") }}</th>
                        </tr>
                        <tr class="body-table">
                            <th>{{ $t("casbee_monitoring.content_collective_housing") }}</th>
                            <th>{{ $t("casbee_monitoring.content_system_performance") }}</th>
                            <th>{{ $t("casbee_monitoring.content_specific_implement") }}</th>
                        </tr>
                    </tbody>
                        </table>
                    </div>
                </div>
                <div class="monitoring__block__body__content">
                    <h2 class="monitoring-table-title">{{ $t("casbee_monitoring.label_exam_efficient_operation") }}</h2>
                    <div class="monitoring-outside-table">
                        <table class="monitoring-table">
                    <thead class="monitoring-table-header">
                        <tr class="header-table">
                            <th>{{ $t("casbee_monitoring.header_table_equipment_item") }}</th>
                            <th>{{ $t("casbee_monitoring.header_table_evaluation_item") }}</th>
                            <th>{{ $t("casbee_monitoring.header_table_evaluation_summary") }}</th>
                        </tr>
                    </thead>
                    <tbody class="monitorng-table-body">
                        <tr class="body-table table-efficiency">
                            <th rowspan="3">{{ $t("casbee_monitoring.content_heat_source_equipment") }}</th>
                            <th>{{ $t("casbee_monitoring.content_heat_source_machine") }}</th>
                            <th>{{ $t("casbee_monitoring.content_heat_storage_tank") }}</th>
                        </tr>
                        <tr class="body-table table-efficiency">
                            <th>{{ $t("casbee_monitoring.content_heat_source_system") }}</th>
                            <th>{{ $t("casbee_monitoring.content_introduction_heat_cool") }}</th>
                        </tr>
                        <tr class="body-table table-efficiency">
                            <th>{{ $t("casbee_monitoring.content_heat_transfer") }}</th>
                            <th>{{ $t("casbee_monitoring.content_transfer_heat_pump") }}</th>
                        </tr>
                        <tr class="body-table table-efficiency">
                            <th rowspan="4">{{ $t("casbee_monitoring.content_air_conditioner") }}</th>
                            <th>{{ $t("casbee_monitoring.content_air_conditioner_transfer") }}</th>
                            <th>{{ $t("casbee_monitoring.content_transfer_heat_fan") }}</th>
                        </tr>
                        <tr class="body-table table-efficiency">
                            <th>{{ $t("casbee_monitoring.content_total_heat_exchanger") }}</th>
                            <th>{{ $t("casbee_monitoring.content_reduce_heat_amount") }}</th>
                        </tr>
                        <tr class="body-table table-efficiency">
                            <th>{{ $t("casbee_monitoring.content_outside_air_cooling") }}</th>
                            <th>{{ $t("casbee_monitoring.content_reduce_heat_amount") }}</th>
                        </tr>
                        <tr class="body-table table-efficiency">
                            <th>{{ $t("casbee_monitoring.content_multi_cop_evaluation") }}</th>
                            <th>{{ $t("casbee_monitoring.content_efficiency_evaluation") }}</th>
                        </tr>
                        <tr class="body-table table-efficiency">
                            <th>{{ $t("casbee_monitoring.content_ventilating_facilities") }}</th>
                            <th>{{ $t("casbee_monitoring.content_evaluation_variable") }}</th>
                            <th></th>
                        </tr>
                        <tr class="body-table table-efficiency">
                            <th>{{ $t("casbee_monitoring.content_light_equipment") }}</th>
                            <th>{{ $t("casbee_monitoring.content_evaluation_various") }}</th>
                            <th>{{ $t("casbee_monitoring.content_reduce_energy") }}</th>
                        </tr>
                        <tr class="body-table table-efficiency">
                            <th rowspan="3">{{ $t("casbee_monitoring.content_hot_water_supply") }}</th>
                            <th>{{ $t("casbee_monitoring.content_heat_source_machine") }}</th>
                            <th>{{ $t("casbee_monitoring.content_consume_heat_equipment") }}</th>
                        </tr>
                        <tr class="body-table table-efficiency">
                            <th>{{ $t("casbee_monitoring.content_heat_source_system") }}</th>
                            <th>{{ $t("casbee_monitoring.content_introduction_heat_cool") }}</th>
                        </tr>
                        <tr class="body-table table-efficiency">
                            <th>{{ $t("casbee_monitoring.content_heat_transfer") }}</th>
                            <th>{{ $t("casbee_monitoring.content_transfer_heat_pump") }}</th>
                        </tr>
                        <tr class="body-table table-efficiency">
                            <th>{{ $t("casbee_monitoring.content_elevator") }}</th>
                            <th>{{ $t("casbee_monitoring.content_operation_effect") }}</th>
                            <th>{{ $t("casbee_monitoring.content_energy_saved") }}</th>
                        </tr>
                        <tr class="body-table table-efficiency">
                            <th rowspan="4">{{ $t("casbee_monitoring.content_other") }}</th>
                            <th>{{ $t("casbee_monitoring.content_photovoltaic_equipment") }}</th>
                            <th>{{ $t("casbee_monitoring.content_annual_efficiency") }}</th>
                        </tr>
                        <tr class="body-table table-efficiency">
                            <th>{{ $t("casbee_monitoring.content_cgs_evaluation") }}</th>
                            <th>{{ $t("casbee_monitoring.content_energy_saving_rate") }}</th>
                        </tr>
                        <tr class="body-table table-efficiency">
                            <th>{{ $t("casbee_monitoring.content_cooperative_controls") }}</th>
                            <th>{{ $t("casbee_monitoring.content_extinguishing_effect") }}</th>
                        </tr>
                        <tr class="body-table table-efficiency">
                            <th>{{ $t("casbee_monitoring.content_other") }}</th>
                            <th>{{ $t("casbee_monitoring.content_control_effect") }}</th>
                        </tr>
                    </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="monitoring__block__note">
                {{ $t("casbee_monitoring.description_regarding_efficiency_evaluation") }}
            </div>
        </div>
    </div>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import { ROUTES } from '@/router/constants';
import PageTitle from '@/components/pageTitle/pageTitle.vue';
export default {
    name: 'monitoring',
    data(){
        return{
            items : [
                {
                    text: this.$t('casbee_monitoring.hyperlink_home'),
                    disabled: false,
                    href: ROUTES.DASHBOARD,
                },
                {
                    text: this.$t('casbee_monitoring.hyperlink_setting'),
                    disabled: false,
                    href: ROUTES.SETTING,
                },
                {
                    text: this.$t('casbee.hyperlink_db_custom_management'),
                    disabled: false,
                    href: ROUTES.CREATE_DATA_CUSTOMIZE,
                },
                {
                    text: this.$t('casbee_monitoring.hyperlink_add_basic_casbee'),
                    disabled: false,
                    href: ROUTES.CASBEE,
                },
                {
                    text: this.$t('casbee_monitoring.label_explain'),
                    disabled: true,
                    href: ROUTES.MONITORING,
                },    
            ]
        }
    },
    components: { PageTitle },
    mounted(){
    this.updateBreadCrumb(this.items);
    },
    methods: {
        ...mapActions('commonApp', ['updateBreadCrumb']),
    }
}
</script>
<style lang="scss" scoped>
.monitoring {
    &__block {
        &__header {
            &__content {
                ::v-deep .page-title__text {
                    font-family: $notoSanFont;
                }
                //padding-left: 40px;
                .monitoring-title {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 22px;
                    font-family: $notoSanFont;
                    line-height: 36px;
                    letter-spacing: 0.05em;
                    color: $goldMid;
                }
            }
        }
        &__body {
            &__content {
                //padding-left: 40px;
                padding-top: 48px;
                &:first-child {
                    padding: 0;
                }
                .monitoring-table-title {
                    font-family: $notoSanFont;
                    margin-bottom: 20px;
                    font-style: normal;
                    font-weight: 700;
                    font-size: 14px;
                    line-height: 20px; 
                    color: #000000;
                }
                .monitoring-outside-table {
                    overflow-x: auto;
                    .monitoring-table {
                        width: max-content;
                        border-collapse: collapse;
                        .monitoring-table-header {
                            background: $bgLight;
                            .header-table {
                                th {
                                    font-style: normal;
                                    font-weight: 400;
                                    font-size: 14px;
                                    font-family: $notoSanFont;
                                    padding: 8px 19px;
                                    line-height: 20px;
                                    color: #000000;
                                    text-align: left;
                                }
                            }
                        }
                        .monitorng-table-body {
                            background: #FFFFFF;
                            .body-table {
                                th{
                                    font-style: normal;
                                    font-weight: 400;
                                    font-size: 14px;
                                    font-family: $notoSanFont;
                                    padding: 8px 19px;
                                    line-height: 20px;
                                    color: #000000;
                                    max-width: 360px;
                                    min-width: 96px;
                                    border-width: 0px 1px 1px 0px;
                                    border-style: solid;
                                    border-color: $monoExtraLight;
                                    text-align: left;
                                    vertical-align: top;
                                    &:nth-child(2) {
                                        min-width: 128px;
                                    }
                                }
                            }
                            .table-efficiency {
                                th:nth-child(2) {
                                    min-width: 200px;
                                }
                                th:nth-child(3) {
                                    min-width: 648px;
                                }
                            }
                        }
                    }
                }
            }
        }
        &__note {
            //margin-left: 40px;
            margin-top: 20px;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            font-family: $notoSanFont;
            line-height: 20px;
            color: #000000;
        }
    }
}
</style>